import classNames from 'classnames';
import type { PropsWithChildren } from 'react';
import { useEffect, useRef, useState } from 'react';

export enum MarqueeScrollerDirection {
  Left = 'left',
  Right = 'right'
}

type MarqueeScrollerDirectionProps = {
  duration?: number;
  direction?: MarqueeScrollerDirection;
  contentClassName?: string;
}

export const MarqueeScroller = ( { children, direction = MarqueeScrollerDirection.Right, duration = 10, contentClassName } : PropsWithChildren<MarqueeScrollerDirectionProps> ) => {
  const childrenRef = useRef<HTMLDivElement>( null );
  const [ width, setWidth ] = useState<number>( 0 );
  const [ translateX, setTranslateX ] = useState<number>( 0 );

  let start = 0;
  const animate = ( timestamp ) => {
    if ( !start ) {
      start = timestamp;
    }

    const progress = timestamp - start;
    const newValue = direction === MarqueeScrollerDirection.Left ? Math.min( width - ( progress / duration ), width * 2 ) : Math.min( progress / duration, width ); // Pas de deler aan voor een snellere of langzamere animatie

    if ( 
      ( direction === MarqueeScrollerDirection.Left && newValue > 0 ) ||
      ( direction === MarqueeScrollerDirection.Right && newValue + width < width * 2 )
    ) {
      setTranslateX( newValue );
    } else {
      start = 0;
      setTranslateX( 0 );
    }

    window.requestAnimationFrame( animate );
  }

  useEffect( () => {
    if ( childrenRef.current ) {
      setWidth( childrenRef.current.clientWidth );
    }

    const handler = window.requestAnimationFrame( animate );
    return () => {
      window.cancelAnimationFrame( handler ) 
    }
  }, [ childrenRef.current ] );

  return (
    <div
      style={ {
        // @ts-ignore
        '--translate-x': `${translateX * -1}px`
      } }
    >
      <div 
        className={ classNames(
          'flex flex-row translate-x-[var(--translate-x)]',
          contentClassName
        ) }
      >
        <div 
          ref={ childrenRef }
          className={ 'shrink-0' }>
          { children }
        </div>
        <div className={ 'shrink-0' }>
          { children }
        </div>
      </div>
    </div>
  )
}